import moment from 'moment';
import currency from "currency.js";
import { decamelize } from 'humps';
import store from '@/store';

export const caclulateOffset = (page, limit) => {
  return (page - 1) * limit;
};

export const calculateDatesByPeriod = (period) => {
  if (period === 'today') {
    return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
  } else if (period === 'yesterday') {
    return [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')];
  } else if (period === 'thisWeek') {
    return [moment().startOf('isoWeek').format('YYYY-MM-DD'), moment().endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'lastWeek') {
    return [moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'thisMonth') {
    return [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
  } else if (period === 'lastMonth') {
    return [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
  }

  return [];
}
export const currencyFormat = (value, currencyCode) => {
  if (typeof value === 'undefined') {
    return '';
  }
  let val = value;
  let symbol = '';

  if (currencyCode === 840) {
    const USD = value => currency(value);
    return USD(value).format();
  } else if (currencyCode === 116) {
    const RIEL = value => currency(value, { precision: 2, symbol: '៛' });
    return RIEL(value).format()
  }

  return currency(value);
}
export const prepareSearchParams = (payload) => {
  const params = {
    ...payload,
    limit: store.state.pagination.perPage,
    page: payload.page,
    sortby: payload.order || "id",
    sort: payload.sort || "desc",
    archived: payload.archived ? 1 : 0,
  };

  return params;
}

export const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const mapAbility = (data, newAbility, initialAbility) => {
  if (data.isReadonly) {
    data.ability.forEach((x) => {
      x.action = "read";
      return x;
    });
  }

  newAbility = [...data.ability, ...initialAbility];
  return newAbility;
}