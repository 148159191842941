export default [
  {
    path: "/item",
    name: "list-item",
    component: () => import("@/views/item/List.vue"),
    meta: {
      pageTitle: "breadcrumb.item",
      resource: "item",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listItem",
          active: true,
        },
      ],
    },
  },
  {
    path: "/item/create",
    name: "create-item",
    component: () => import("@/views/item/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.item",
      navActiveLink: "list-item",
      resource: "item",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listItem",
          to: { name: "list-item" },
        },
        {
          text: "breadcrumb.createItem",
          active: true,
        },
      ],
    },
  },
  {
    path: "/item/:id/update",
    name: "update-item",
    component: () => import("@/views/item/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.item",
      navActiveLink: "list-item",
      resource: "item",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listItem",
          to: { name: "list-item" },
        },
        {
          text: "breadcrumb.updateItem",
          active: true,
        },
      ],
    },
  },
];
