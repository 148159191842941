import ProfileRepository from "./Api/ProfileRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import FileRepository from "./Api/FileRepository";
import EmployeeRepository from "./Api/EmployeeRepository";
import ItemRepository from "./Api/ItemRepository";
import InvoiceRepository from "./Api/InvoiceRepository";

const repositories = {
    'profile': ProfileRepository,
    'user': UserRepository,
    'role': RoleRepository,
    'permission': PermissionRepository,
    'file': FileRepository,
    'employee': EmployeeRepository,
    'item': ItemRepository,
    'invoice': InvoiceRepository,
};

export default {
    get: name => repositories[name]
};
