import CryptoJS from 'crypto-js';

const key = 'NPvUM8sb6QWXlfOhONuOlX75C0FuryoG';
const iv = 'CsmlVarrCF0bkXRz';

export function encrypt(text) {
    const encrypt = text + "-" + new Date().getTime()

    var ciphertext = CryptoJS.AES.encrypt(encrypt, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    }).toString();

    return ciphertext;
}

export function decrypt(text) {
    var ciphertext = CryptoJS.AES.decrypt(text, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    return ciphertext.toString(CryptoJS.enc.Utf8);
}

export function encryptText(text) {
    const encrypt = text;

    var ciphertext = CryptoJS.AES.encrypt(encrypt, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    }).toString();

    return ciphertext;
}