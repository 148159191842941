export default [
  {
    path: "/invoice",
    name: "list-invoice",
    component: () => import("@/views/invoice/List.vue"),
    meta: {
      pageTitle: "breadcrumb.invoice",
      resource: "invoice",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listInvoice",
          active: true,
        },
      ],
    },
  },
];
